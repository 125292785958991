<template>
  <div class="w-full relative flex items-center rounded-2xl shadow-sm bg-white">
    <div
      class="absolute inset-y-0 left-0 pl-6 flex items-center pointer-events-none"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="h-5 w-5 text-[#9E9BA3]"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
        />
      </svg>
    </div>
    <input
      type="search"
      name="search"
      id="search"
      class="focus:ring-pink-600 focus:border-pink-600 block w-full pl-14 pr-6 text-base border-gray-300 py-4 rounded-2xl outline-none bg-white"
      :placeholder="placeholder"
      @click="wrapScrollTo()"
      v-model="store.textSearch"
    />
  </div>
</template>

<script>
// import { MagnifyingGlassIcon } from "@heroicons/vue/solid";
import { usePrices } from "../store/price";
import { scrollTo } from "../libs/ui";
import { useDocs } from "../store/docs";
import { useServices } from "../store/services";
import { useSpecials } from "../store/specials";

export default {
  props: ["placeholder", "type", "scrollIn", "scroll"],
  setup(props) {
    let store;
    switch (props.type) {
      case "price":
        store = usePrices();
        break;
      case "docs":
        store = useDocs();
        break;
      case "service":
        store = useServices();
        break;
      case "special":
        store = useSpecials();
        break;
    }

    return { scrollTo, store };
  },
  components: {
    // MagnifyingGlassIcon,
  },
  methods: {
    wrapScrollTo() {
      console.log(this.scroll === false);
      if (this.scroll === false) return;
      setTimeout(() => {
        if (!this.scrollIn) {
          this.scrollTo("search", window, 30);
        } else {
          // console.log(321);

          this.scrollTo("search", this.scrollIn, 60);
        }
      }, 100);
    },
  },
};
</script>
